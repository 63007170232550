import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilTag,

  cifAd,
  cifAe,
  cifAf,
  cifAg,
  cifAl,
  cifAm,
  cifAo,
  cifAr,
  cifAt,
  cifAu,
  cifAz,
  cifBa,
  cifBb,
  cifBd,
  cifBe,
  cifBf,
  cifBg,
  cifBh,
  cifBi,
  cifBj,
  cifBn,
  cifBo,
  cifBr,
  cifBs,
  cifBt,
  cifBw,
  cifBy,
  cifBz,
  cifCa,
  cifCd,
  cifCf,
  cifCg,
  cifCh,
  cifCi,
  cifCl,
  cifCm,
  cifCn,
  cifCo,
  cifCr,
  cifCu,
  cifCv,
  cifCy,
  cifCz,
  cifDe,
  cifDj,
  cifDk,
  cifDm,
  cifDo,
  cifDz,
  cifEc,
  cifEe,
  cifEg,
  cifEr,
  cifEs,
  cifEt,
  cifFi,
  cifFj,
  cifFm,
  cifFr,
  cifGa,
  cifGb,
  cifGd,
  cifGe,
  cifGh,
  cifGm,
  cifGn,
  cifGq,
  cifGr,
  cifGt,
  cifGw,
  cifGy,
  cifHk,
  cifHn,
  cifHr,
  cifHt,
  cifHu,
  cifId,
  cifIe,
  cifIl,
  cifIn,
  cifIq,
  cifIr,
  cifIs,
  cifIt,
  cifJm,
  cifJo,
  cifJp,
  cifKe,
  cifKg,
  cifKh,
  cifKi,
  cifKm,
  cifKn,
  cifKp,
  cifKr,
  cifKw,
  cifKz,
  cifLa,
  cifLb,
  cifLc,
  cifLi,
  cifLk,
  cifLr,
  cifLs,
  cifLt,
  cifLu,
  cifLv,
  cifLy,
  cifMa,
  cifMc,
  cifMd,
  cifMe,
  cifMg,
  cifMh,
  cifMk,
  cifMl,
  cifMm,
  cifMn,
  cifMr,
  cifMt,
  cifMu,
  cifMv,
  cifMw,
  cifMx,
  cifMy,
  cifMz,
  cifNa,
  cifNe,
  cifNg,
  cifNi,
  cifNl,
  cifNo,
  cifNp,
  cifNr,
  cifNu,
  cifNz,
  cifOm,
  cifPa,
  cifPe,
  cifPg,
  cifPh,
  cifPk,
  cifPl,
  cifPt,
  cifPw,
  cifPy,
  cifQa,
  cifRo,
  cifRs,
  cifRu,
  cifRw,
  cifSa,
  cifSb,
  cifSc,
  cifSd,
  cifSe,
  cifSg,
  cifSi,
  cifSk,
  cifSl,
  cifSm,
  cifSn,
  cifSo,
  cifSr,
  cifSs,
  cifSt,
  cifSv,
  cifSy,
  cifSz,
  cifTd,
  cifTg,
  cifTh,
  cifTj,
  cifTl,
  cifTm,
  cifTn,
  cifTo,
  cifTr,
  cifTt,
  cifTv,
  cifTw,
  cifTz,
  cifUa,
  cifUg,
  cifUs,
  cifUy,
  cifUz,
  cifVa,
  cifVc,
  cifVe,
  cifVn,
  cifWs,
  cifXk,
  cifYe,
  cifZa,
  cifZm,
  cifZw,

  cilAccountLogout,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilFlagAlt,
  cilMoney,
  cilBook,
  cilBalanceScale,
  cilPlus,
  cilClone,
  cilEqualizer,
  cilCloudUpload
} from '@coreui/icons';
import {
  cisChevronLeft,
  cisSettings,
  cisSettingsAlt,
  cilBoxAlt,
  cisFilePdf,
  cilMinusCircle,
  cisBookmark,
  cilKey
} from '@coreui/icons-pro';
import { analytics } from './analytics';
import { marketing } from './marketing';
import { messages } from './messages';
import { overview } from './overview';
import { payments } from './payments';
import { packages } from './packages';
import { referrals } from './referrals';
import { currency } from './currency';
import { support } from './support';
import { hourglass } from './hourglass';
import { info } from './info';
import { trophy } from './trophy';
import { positions } from './positions';
import { competition } from './competition';
import { gift } from './gift';

export const icons = {
  analytics,
  marketing,
  messages,
  overview,
  payments,
  packages,
  referrals,
  support,
  currency,
  hourglass,
  gift,
  info,
  trophy,
  competition,
  positions,
  cilMoney,
  cilFlagAlt,
  cilAccountLogout,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilBoxAlt,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilBalanceScale,
  cilPlus,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilBook,
  cilCheckAlt,
  cilMinusCircle,
  cilClone,
  cilEqualizer,
  cilKey,
  cilTag,
  cilCloudUpload,

  cifAd,
  cifAe,
  cifAf,
  cifAg,
  cifAl,
  cifAm,
  cifAo,
  cifAr,
  cifAt,
  cifAu,
  cifAz,
  cifBa,
  cifBb,
  cifBd,
  cifBe,
  cifBf,
  cifBg,
  cifBh,
  cifBi,
  cifBj,
  cifBn,
  cifBo,
  cifBr,
  cifBs,
  cifBt,
  cifBw,
  cifBy,
  cifBz,
  cifCa,
  cifCd,
  cifCf,
  cifCg,
  cifCh,
  cifCi,
  cifCl,
  cifCm,
  cifCn,
  cifCo,
  cifCr,
  cifCu,
  cifCv,
  cifCy,
  cifCz,
  cifDe,
  cifDj,
  cifDk,
  cifDm,
  cifDo,
  cifDz,
  cifEc,
  cifEe,
  cifEg,
  cifEr,
  cifEs,
  cifEt,
  cifFi,
  cifFj,
  cifFm,
  cifFr,
  cifGa,
  cifGb,
  cifGd,
  cifGe,
  cifGh,
  cifGm,
  cifGn,
  cifGq,
  cifGr,
  cifGt,
  cifGw,
  cifGy,
  cifHk,
  cifHn,
  cifHr,
  cifHt,
  cifHu,
  cifId,
  cifIe,
  cifIl,
  cifIn,
  cifIq,
  cifIr,
  cifIs,
  cifIt,
  cifJm,
  cifJo,
  cifJp,
  cifKe,
  cifKg,
  cifKh,
  cifKi,
  cifKm,
  cifKn,
  cifKp,
  cifKr,
  cifKw,
  cifKz,
  cifLa,
  cifLb,
  cifLc,
  cifLi,
  cifLk,
  cifLr,
  cifLs,
  cifLt,
  cifLu,
  cifLv,
  cifLy,
  cifMa,
  cifMc,
  cifMd,
  cifMe,
  cifMg,
  cifMh,
  cifMk,
  cifMl,
  cifMm,
  cifMn,
  cifMr,
  cifMt,
  cifMu,
  cifMv,
  cifMw,
  cifMx,
  cifMy,
  cifMz,
  cifNa,
  cifNe,
  cifNg,
  cifNi,
  cifNl,
  cifNo,
  cifNp,
  cifNr,
  cifNu,
  cifNz,
  cifOm,
  cifPa,
  cifPe,
  cifPg,
  cifPh,
  cifPk,
  cifPl,
  cifPt,
  cifPw,
  cifPy,
  cifQa,
  cifRo,
  cifRs,
  cifRu,
  cifRw,
  cifSa,
  cifSb,
  cifSc,
  cifSd,
  cifSe,
  cifSg,
  cifSi,
  cifSk,
  cifSl,
  cifSm,
  cifSn,
  cifSo,
  cifSr,
  cifSs,
  cifSt,
  cifSv,
  cifSy,
  cifSz,
  cifTd,
  cifTg,
  cifTh,
  cifTj,
  cifTl,
  cifTm,
  cifTn,
  cifTo,
  cifTr,
  cifTt,
  cifTv,
  cifTw,
  cifTz,
  cifUa,
  cifUg,
  cifUs,
  cifUy,
  cifUz,
  cifVa,
  cifVc,
  cifVe,
  cifVn,
  cifWs,
  cifXk,
  cifYe,
  cifZa,
  cifZm,
  cifZw,
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cisChevronLeft,
  cisSettings,
  cisSettingsAlt,
  cisFilePdf,
  cisBookmark
};
