import { IReferralFilters } from '../../reducers/filtersReducer';

export const getFiltersCount = (filters: IReferralFilters) => {
  const objectKeys = Object.keys(filters);
  return Object.values(filters).reduce<number>(
    (count, filterValue, currentIndex) => {
      const filterKey = objectKeys[currentIndex];
      const notToggleSelect = filterKey === 'sources';
      if (
        (filterValue && !Array.isArray(filterValue)) ||
        (Array.isArray(filterValue) &&
          filterValue.length > 0 &&
          !notToggleSelect)
      ) {
        return count + 1;
      }
      if (filterKey === 'sources') {
        const foundEnabled = filters.sources.find((s: any) => s.enabled);
        if (foundEnabled) {
          return count + 1;
        }
      }
      return count;
    },
    0
  );
};
