import React from 'react';
import { CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react';
import _t from 'counterpart';
import { MainTitle } from '../components/MainTitle/MainTitle';
import { loadBookmarkedCustomers } from '../services/BackendService';
import ReferralsTable from './ReferralsPage/ReferralsTable';

const BookmarksPage = () => {
  return (
    <CContainer fluid className="c-main main-holder">
      <CRow>
        <CCol>
          <MainTitle>{_t('sidebar.bookmarked-users')}</MainTitle>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <ReferralsTable
                queryKey="bookmarks"
                fetch={loadBookmarkedCustomers}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default BookmarksPage;
