import React from 'react';
import _t from 'counterpart';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';

interface IProps {
	onRetry: () => void;
}

const Error = ({ onRetry }: IProps) => {
	return (
		<div className="d-flex flex-grow-1 justify-content-center align-items-center flex-column my-2">
			<div className="d-flex flex-column justify-content-center align-items-center">
				<CIcon name="warning" size="2xl" className="mb-4" />
				<h1 className="error-header">{_t('global.something-went-wrong')}</h1>
				<span className="w-75 text-center">{_t('global.trouble-reaching-servers')}</span>
				<CButton color="primary" className="mt-4 pl-3 pr-3" onClick={onRetry}>
					{_t('action.retry')}
				</CButton>
			</div>
		</div>
	);
};

export default Error;
