import React from 'react';
import { connect } from 'react-redux';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CImg,
  CToggler,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';
import { AppState } from '../reducers';
import {
  oAuthLogoutRequested,
  requestChangeLanguage,
  toggleSidebar,
  toggleSidebarMobile,
} from '../actions';
import { capitalize, getIconNameForLanguage } from '../helpers';
import logo from '../images/logo';
import { supportedLocales } from '../locales';
import { useAppSelector } from '../helpers/customHooks';

interface IAppHeaderProps {
  userName: string;
  avatarUrl: string;
  logout: () => void;
  toggleSidebar: () => void;
  toggleSidebarMobile: () => void;
  changeLanguage: (language: string) => void;
}

interface LanguageDropdownProps {
  changeLanguage: (language: string) => void;
}

const LanguageDropdown = ({ changeLanguage }: LanguageDropdownProps) => {
  const currentLanguage = useAppSelector((state) => state.core.language);
  const setLanguage = async (language: string) => {
    changeLanguage(language);
  };

  return (
    <CDropdown
      inNav
      className="c-header-nav-items"
    >
      <CDropdownToggle className="c-header-nav-link dropdown-toggle" caret={false}>
        {getIconNameForLanguage(currentLanguage)
        && <CIcon name={getIconNameForLanguage(currentLanguage)!} size="xl" className="d-lg-none" />}
        <p className="px-2 mb-0 d-md-down-none">
          {capitalize(_t('global.language'))}
        </p>
      </CDropdownToggle>
      <CDropdownMenu className="py-0" placement="bottom-end">
        {supportedLocales.map((language) => (
          <button
            type="button"
            onClick={() => setLanguage(language)}
            className="wrapper-button"
            key={`lang-${language}`}
          >
            <CDropdownItem
              active={currentLanguage === language}
            >
              {getIconNameForLanguage(language)
              && <CIcon name={getIconNameForLanguage(language)!} size="custom-size" title={language} />}
              <span>{_t(`languages.${language}`)}</span>
            </CDropdownItem>
          </button>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};

const AppHeader = ({
  changeLanguage,
  userName,
  avatarUrl,
  logout,
  toggleSidebar,
  toggleSidebarMobile,
}: IAppHeaderProps) => {
  return (
    <CHeader>
      <CToggler
        inHeader
        className="d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="d-lg-none" to="/">
        <CImg src={logo} height="48" />
      </CHeaderBrand>

      <CHeaderNav className="ml-auto mr-3">
        <LanguageDropdown changeLanguage={changeLanguage} />

        <CDropdown
          inNav
          className="c-header-nav-items"
        >
          <CDropdownToggle className="c-header-nav-link dropdown-toggle" caret={false}>
            <div className="c-avatar">
              <CImg
                src={avatarUrl}
                className="c-avatar-img"
                style={{ overflow: 'hidden' }}
                alt={userName}
              />
            </div>
            <p className="px-2 mb-0 d-none">
              {userName}
            </p>
          </CDropdownToggle>
          <CDropdownMenu className="py-0" placement="bottom-end">
            <CDropdownItem href="/profile">
              <CIcon name="cil-user" className="mfe-2" />
              {_t('header.profile')}
            </CDropdownItem>
            <CDropdownItem onClick={logout}>
              <CIcon name="cil-account-logout" className="mfe-2" />
              {_t('header.logout')}
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CHeaderNav>
    </CHeader>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    userName: state.user.name!,
    avatarUrl: state.user.avatarUrl!,
  };
};

const mapDispatchToProps = {
  logout: oAuthLogoutRequested,
  toggleSidebarMobile,
  toggleSidebar,
  changeLanguage: requestChangeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
