import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import userReducer, { UserState } from './userReducer';
import coreReducer, { CoreState } from './coreReducer';
import notificationsReducer, { NotificationsState } from './notificationsReducer';
import loginReducer, { LoginState } from './loginReducer';
import filtersReducer, { IFiltersState } from './filtersReducer';

export interface AppState {
	router: RouterState;
	user: UserState;
	login: LoginState;
	core: CoreState;
	notifications: NotificationsState;
	filters: IFiltersState;
}

const createRootReducer = (history: any) =>
	combineReducers<AppState>({
		router: connectRouter(history),
		user: userReducer,
		login: loginReducer,
		core: coreReducer,
		notifications: notificationsReducer,
		filters: filtersReducer,
	});

export default createRootReducer;
