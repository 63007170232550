import React from 'react';
import { connect } from 'react-redux';
import {
  CImg,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarNav,
  CSidebarNavItem,
} from '@coreui/react';
import _t from 'counterpart';
import { useLocation } from 'react-router-dom';
import { AppState } from '../reducers';
import { setSidebarShown } from '../actions';
import { UserRole } from '../reducers/userReducer';
import { capitalize } from '../helpers';
import logo from '../images/logo';
import { useQueryClient } from 'react-query';

const SidebarFooter = ({ isAdmin }: { isAdmin: boolean }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <CSidebarNav>
      {process.env.BRAND === '24domino' && (
        <CSidebarNavItem
          name={_t('sidebar.how-it-works')}
          icon="info"
          to="/howitworks"
          replace={path === '/howitworks'}
        />
      )}
      <CSidebarNavItem
        name={_t('sidebar.support')}
        icon="support"
        to="/support"
        color="secondary"
        replace={path === '/support'}
      />
      {process.env.REACT_APP_ADMIN_URL && isAdmin && (
        <CSidebarNavItem
          name={_t('sidebar.admin-area')}
          icon="cis-settings-alt"
          href={process.env.REACT_APP_ADMIN_URL}
          target="_blank"
          color="success"
          className="font-weight-bold"
        />
      )}
    </CSidebarNav>
  );
};

const MainNavigation = ({ isLeader }: { isLeader: boolean }) => {
  const location = useLocation();
  const path = location.pathname;

  const queryClient = useQueryClient();
  const greyLabelingEnabled = queryClient.getQueryData(['greyLabelingEnabled']);
  return (
    <CSidebarNav>
      <CSidebarNavItem
        name={capitalize(_t('sidebar.overview'))}
        icon="overview"
        to="/"
        replace={path === '/'}
      />
      <CSidebarNavItem
        name={_t('sidebar.referrals')}
        icon="referrals"
        to="/referrals"
        replace={path === '/referrals'}
      />
      <CSidebarNavItem
        name={_t('sidebar.marketing')}
        icon="marketing"
        to="/marketing"
        replace={path === '/marketing'}
      />
      {isLeader && (
        <CSidebarNavItem
          name={capitalize(_t('sidebar.analytics'))}
          icon="analytics"
          to="/analytics"
          replace={path === '/analytics'}
        />
      )}
      <CSidebarNavItem
        name={_t('sidebar.messages')}
        icon="messages"
        to="/messages"
        replace={path === '/messages'}
      />
      <CSidebarNavItem
        name={_t('sidebar.bookmarked-users')}
        icon="cilBookmark"
        to="/bookmarks"
        replace={path === '/bookmarks'}
      />
      <CSidebarNavItem
        name={_t('sidebar.api-keys')}
        icon="cilKey"
        to="/api_keys"
        replace={path === '/api_keys'}
      />
      <CSidebarNavItem
        name={_t('sidebar.commissions')}
        icon="cilBalanceScale"
        to="/commissions"
        replace={path === '/commissions'}
      />
      {greyLabelingEnabled && (
        <CSidebarNavItem
          name={_t('sidebar.my-brand')}
          icon="cilTag"
          to="/my_brand"
          replace={path === '/my_brand'}
        />
      )}
    </CSidebarNav>
  );
};

interface IAffiliateSidebarProps {
  isLeader: boolean;
  isAdmin: boolean;
  sidebarShown: boolean | '' | 'responsive';
  setSidebarShown: (val: boolean | '' | 'responsive') => void;
}

const AffiliateSidebar = ({
  sidebarShown,
  setSidebarShown,
  isAdmin,
  isLeader,
}: IAffiliateSidebarProps) => (
  <CSidebar
    show={sidebarShown}
    onShowChange={(val: boolean | '' | 'responsive') => setSidebarShown(val)}
  >
    <CSidebarBrand className="d-md-down-none" to="/">
      <CImg src={logo} className="c-sidebar-brand-full" height={35} />
      <CImg src={logo} className="c-sidebar-brand-minimized" height={35} />
    </CSidebarBrand>
    <MainNavigation isLeader={isLeader} />
    <CSidebarFooter className="p-0">
      <SidebarFooter isAdmin={isAdmin} />
    </CSidebarFooter>
  </CSidebar>
);

const mapStateToProps = (state: AppState) => ({
  sidebarShown: state.core.sidebarShown,
  isLeader: !!state.user?.roles?.includes(UserRole.Leader),
  isAdmin: !!state.user?.roles?.includes(UserRole.Admin),
});

const mapDispatchToProps = {
  setSidebarShown,
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateSidebar);
