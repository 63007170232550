/*
 * action types
 */

export const SHOW_ERROR_TOAST = 'SHOW_ERROR_TOAST';
export const HIDE_ERROR_TOAST = 'SHOW_ERROR_TOAST';

export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const HIDE_SUCCESS_TOAST = 'HIDE_SUCCESS_TOAST';

export const REQUEST_OAUTH_TOKENS = 'REQUEST_OAUTH_TOKENS';

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
export const UPDATE_USER_REFRESH_TOKEN = 'UPDATE_USER_REFRESH_TOKEN';

export const SET_LOGIN_ERROR_MESSAGE = 'SET_LOGIN_ERROR_MESSAGE';

export const LOGOUT = 'LOGOUT';
export const OAUTH_LOGOUT_REQUESTED = 'OAUTH_LOGOUT_REQUESTED';

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const HIDE_GLOBAL_LOADER = 'HIDE_GLOBAL_LOADER';

export const LOAD_USER_REQUESTED = 'LOAD_USER_REQUESTED';
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';

export const LOAD_TRANSACTIONS_REQUEST = 'LOAD_TRANSACTIONS_REQUEST';
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const LOAD_TRANSACTIONS_FAILURE = 'LOAD_TRANSACTIONS_FAILURE';

export const REQUEST_TRANSACTION_REQUEST = 'REQUEST_TRANSACTION_REQUEST';
export const REQUEST_TRANSACTION_SUCCESS = 'REQUEST_TRANSACTION_SUCCESS';
export const REQUEST_TRANSACTION_FAILURE = 'REQUEST_TRANSACTION_FAILURE';

export const UPDATE_REFKEY_REQUEST = 'UPDATE_REFKEY_REQUEST';
export const UPDATE_REFKEY_SUCCESS = 'UPDATE_REFKEY_SUCCESS';
export const UPDATE_REFKEY_FAILURE = 'UPDATE_REFKEY_FAILURE';

export const LOAD_BALANCE_REQUEST = 'LOAD_BALANCE_REQUEST';
export const LOAD_BALANCE_SUCCESS = 'LOAD_BALANCE_SUCCESS';
export const LOAD_BALANCE_FAILURE = 'LOAD_BALANCE_FAILURE';

export const LOAD_TRADING_BALANCE_REQUEST = 'LOAD_TRADING_BALANCE_REQUEST';
export const LOAD_TRADING_BALANCE_SUCCESS = 'LOAD_TRADING_BALANCE_SUCCESS';
export const LOAD_TRADING_BALANCE_FAILURE = 'LOAD_TRADING_BALANCE_FAILURE';

export const LOAD_CUSTOMER_TRADING_BALANCE_REQUEST = 'LOAD_CUSTOMER_TRADING_BALANCE_REQUEST';
export const LOAD_CUSTOMER_TRADING_BALANCE_SUCCESS = 'LOAD_CUSTOMER_TRADING_BALANCE_SUCCESS';
export const LOAD_CUSTOMER_TRADING_BALANCE_FAILURE = 'LOAD_CUSTOMER_TRADING_BALANCE_FAILURE';

export const SET_SIDEBAR_SHOWN = 'SET_SIDEBAR_SHOWN';
export const TOGGLE_SIDEBAR_MOBILE = 'TOGGLE_SIDEBAR_MOBILE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const REQUEST_CHANGE_LANGUAGE = 'REQUEST_CHANGE_LANGUAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_COMMISSION_FILTERS = 'SET_COMMISSION_FILTERS';
export const CLEAR_COMMISSION_FILTERS = 'CLEAR_COMMISSION_FILTERS';

export const SET_REFERRAL_FILTERS = 'SET_REFERRAL_FILTERS';
export const CLEAR_REFERRAL_FILTERS = 'CLEAR_REFERRAL_FILTERS';

export const SET_PAYOUT_REQUEST_FILTERS = 'SET_PAYOUT_REQUEST_FILTERS';
export const CLEAR_PAYOUT_REQUEST_FILTERS = 'CLEAR_PAYOUT_REQUEST_FILTERS';

/*
 * other constants
 */

export enum DashboardPeriods {
	Last7Days = 'LAST_7_DAYS',
	Last30Days = 'LAST_30_DAYS',
	Last24Hours = 'LAST_24_HOURS',
}

export enum UserCategory {
	Registered = 'REGISTERED',
	Deposited = 'DEPOSITED',
}

/*
 * action creators
 */

export function showErrorToast(message: string) {
	return { type: SHOW_ERROR_TOAST, message };
}

export function hideErrorToast() {
	return { type: HIDE_ERROR_TOAST };
}

export function showSuccessToast(message: string) {
	return { type: SHOW_SUCCESS_TOAST, message };
}

export function hideSuccessToast() {
	return { type: HIDE_SUCCESS_TOAST };
}

export function requestOAuthTokens(code: string) {
	return { type: REQUEST_OAUTH_TOKENS, code };
}

export function updateUserToken(token: string) {
	return { type: UPDATE_USER_TOKEN, token };
}

export function updateUserRefreshToken(refreshToken: string) {
	return { type: UPDATE_USER_REFRESH_TOKEN, refreshToken };
}

export function setLoginErrorMessage(message: string) {
	return { type: SET_LOGIN_ERROR_MESSAGE, message };
}

export function successLogin(user: any, token: string, refreshToken: string) {
	return { type: LOGIN_SUCCEEDED, user, token, refreshToken };
}

export function failureLogin(message: string) {
	return { type: LOGIN_FAILED, message };
}

export function logout() {
	return { type: LOGOUT };
}

export function oAuthLogoutRequested() {
	return { type: OAUTH_LOGOUT_REQUESTED };
}

export function showGlobalLoader() {
	return { type: SHOW_GLOBAL_LOADER };
}

export function hideGlobalLoader() {
	return { type: HIDE_GLOBAL_LOADER };
}

export function requestLoadUser() {
	return { type: LOAD_USER_REQUESTED };
}

export function failureLoadUser(message: string) {
	return { type: LOAD_USER_FAILED, message };
}

export function successLoadUser(user: any) {
	return { type: LOAD_USER_SUCCESS, user };
}

export function setSidebarShown(shown: boolean | string) {
	return { type: SET_SIDEBAR_SHOWN, payload: { shown } };
}

export function toggleSidebarMobile() {
	return { type: TOGGLE_SIDEBAR_MOBILE };
}

export function toggleSidebar() {
	return { type: TOGGLE_SIDEBAR };
}

export function requestUpdateRefkey(refKey: string) {
	return { type: UPDATE_REFKEY_REQUEST, refKey };
}

export function successUpdateRefkey() {
	return { type: UPDATE_REFKEY_SUCCESS };
}

export function failureUpdateRefkey(message: string) {
	return { type: UPDATE_REFKEY_FAILURE, message };
}

export function requestChangeLanguage(language: string, skipUserUpdate: boolean = false) {
	return {
		type: REQUEST_CHANGE_LANGUAGE,
		language,
		skipUserUpdate,
	};
}

export function setLanguage(language: string, skipUserUpdate: boolean = false) {
	return {
		type: SET_LANGUAGE,
		language,
		skipUserUpdate,
	};
}

export function setCommissionFilters(filters: any) {
	return {
		type: SET_COMMISSION_FILTERS,
		payload: { filters },
	};
}

export function clearCommissionFilters() {
	return { type: CLEAR_COMMISSION_FILTERS };
}

export function setReferralFilters(filters: any) {
	return {
		type: SET_REFERRAL_FILTERS,
		payload: { filters },
	};
}

export function clearReferralFilters() {
	return { type: CLEAR_REFERRAL_FILTERS };
}

export function setPayoutRequestFilters(filters: any) {
	return {
		type: SET_PAYOUT_REQUEST_FILTERS,
		payload: { filters },
	};
}

export function clearPayoutRequestFilters() {
	return { type: CLEAR_PAYOUT_REQUEST_FILTERS };
}
