import React, { useCallback, useState } from 'react';
import Select, { components } from 'react-select';
import _t from 'counterpart';

export interface ISelectOption {
  value: string;
  label: string;
  enabled: boolean;
}

interface IProps {
  data: Array<ISelectOption>;
  onChange: (option: ISelectOption) => void;
  onAllChange: (checked: boolean) => void;
}

interface ICustomOption {
  data: {
    value: string;
    label: string;
    enabled: boolean;
  };
}

const SelectMultipleFilter = ({ data, onChange, onAllChange }: IProps) => {
  const [input, setInput] = useState<string>('');

  const handleInputValue = (input: string) => {
    setInput(input);
  };

  const onOptionChange = useCallback(
    (option: ISelectOption) => {
      onChange(option);
    },
    [onChange]
  );

  const customOption = useCallback(
    (option: ICustomOption) => {
      const { data: record } = option;
      const { label, value, enabled } = record;

      return (
        <div className="p-2 custom-select-row">
          <span>{label}</span>
          <input
            type="checkbox"
            value={value}
            checked={enabled}
            onChange={() => onOptionChange(record)}
          />
        </div>
      );
    },
    [onOptionChange]
  );

  const onAllChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    onAllChange(isChecked);
  };

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        <div className="d-flex flex-column border-bottom">
          <div className="p-2 custom-select-row">
            <span>{_t('global.all')}</span>
            <input
              type="checkbox"
              checked={!data.find((value: ISelectOption) => !value.enabled)}
              onChange={onAllChanged}
            />
          </div>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  return (
    <Select
      components={{ Option: customOption, MenuList }}
      options={data}
      isMulti
      closeMenuOnSelect={false}
      inputValue={input}
      onInputChange={handleInputValue}
      className="customer-toggle-select"
    />
  );
};

export default SelectMultipleFilter;
