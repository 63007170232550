import React, { useState } from 'react';
import {
	CButton,
	CButtonToolbar,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CForm,
	CFormGroup,
	CFormText,
	CInput,
	CLabel,
	CRow,
	CSelect,
} from '@coreui/react';
import MessageBox from '../../components/MessageBox';
import MessageTypeBadge from '../../components/MessageTypeBadge';
import { extractErrorMessage, formatDateTime, truncate } from '../../helpers';
import _t from 'counterpart';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { loadCustomerMessages, sendCustomerMessage } from '../../services/BackendService';
import { ICustomer, MessageType } from '../../types';
import { CONTACT_METHODS } from '../../config';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../actions';
import { NumberParam, useQueryParam } from 'use-query-params';
import PaginationTable from '../../components/PaginationTable';

interface IProps {
	customerId: string;
	customer: ICustomer;
}

interface ISendCustomerMessageParams {
	type: MessageType;
	messageText: string;
	messageSubject: string;
}

const CustomerMessages = ({ customerId, customer }: IProps) => {
	const [messageType, setMessageType] = useState<MessageType | null>(null);
	const [messageText, setMessageText] = useState<string>('');
	const [messageSubject, setMessageSubject] = useState<string>('');
	const [page, setPage] = useQueryParam('page', NumberParam);

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const handleSendCustomerMessage = () => {
		sendCustomerMessageMutation.mutate({ type: messageType!, messageText, messageSubject });
	};

	const loadMessagesQuery = useQuery(['messages', customerId, page], () =>
		loadCustomerMessages(customerId, limit, offset)
	);

	const pages = Math.round((loadMessagesQuery.data?.count || 0) / limit);

	const sendCustomerMessageMutation = useMutation(
		(params: ISendCustomerMessageParams) =>
			sendCustomerMessage(params.type, customerId, params.messageText, params.messageSubject),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t('messages.message-sent-successfully')));
				queryClient.invalidateQueries(['messages', customerId]);
			},
			onError: (e) => {
				dispatch(showErrorToast(extractErrorMessage(e)));
			},
		}
	);

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
		queryClient.invalidateQueries(['messages', customerId]);
	};

	const contactMethods = CONTACT_METHODS.filter((c) => customer.availableContactMethods.includes(c.id));

	const updateMessageSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMessageSubject(e.target.value);
	};

	return (
		<CRow>
			<CCol md={6}>
				<CCard>
					<CCardHeader>
						<CCardTitle>{_t('action.send-new-message')}</CCardTitle>
					</CCardHeader>
					<CCardBody>
						<CForm action="" method="post">
							<CFormGroup>
								<CLabel htmlFor="nf-email">{_t('customer.contact-method')}</CLabel>
								<CSelect
									id="nf-target-group"
									name="nf-target-group"
									onChange={(event: any) => setMessageType(event.target.value)}
								>
									<option value="">{_t('action.please-select')}</option>
									{contactMethods.map((t) => (
										<option value={t.id} key={`target-group-${t.id}`}>
											{t.name}
										</option>
									))}
									x
								</CSelect>
								<CFormText className="help-block">{_t('customer.please-note')}</CFormText>
							</CFormGroup>

							<CFormGroup>
								<CLabel>{_t('messages.subject')}</CLabel>
								<CInput type="text" value={messageSubject} onChange={updateMessageSubject} />
							</CFormGroup>

							<MessageBox error={sendCustomerMessageMutation.error} onChange={setMessageText} />

							<CButtonToolbar justify="end">
								<CButton
									color="primary"
									disabled={!messageType || messageText.length < 1}
									onClick={handleSendCustomerMessage}
								>
									{_t('action.send')}
								</CButton>
							</CButtonToolbar>
						</CForm>
					</CCardBody>
				</CCard>
			</CCol>
			<CCol md={6}>
				<CCard>
					<CCardHeader>
						<CCardTitle>{_t('sidebar.messages')}</CCardTitle>
					</CCardHeader>
					<CCardBody>
						<PaginationTable
							scopedSlots={{
								date: (message: any) => <td className="align-middle">{formatDateTime(message.createdAt)}</td>,
								type: (message: any) => (
									<td className="align-middle">
										<MessageTypeBadge type={message.type} />
									</td>
								),
								text: (message: any) => <td className="align-middle">{truncate(message.text, 40)}</td>,
								status: (message: any) => <td className="align-middle">{message.status}</td>,
							}}
							tableFields={[
								{ key: 'date', label: _t('global.date') },
								{ key: 'type', label: _t('global.type') },
								{ key: 'text', label: _t('global.text'), sorter: false },
								{ key: 'status', label: _t('global.status') },
							]}
							data={loadMessagesQuery.data?.messages || []}
							activePage={page || 1}
							pages={pages || 0}
							pagination
							onPageChanged={onPageChanged}
							loading={false}
						/>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	);
};

export default CustomerMessages;
