import React from 'react';
import { useParams } from 'react-router-dom';
import { CContainer } from '@coreui/react';
import Loading from '../../components/Loading';

import { useQuery } from 'react-query';
import { loadCustomer } from '../../services/BackendService';
import { NetworkError } from '../../components/NetworkError/NetworkError';
import CustomerDetails from './CustomerDetails';
import CustomerPageHeader from './CustomerPageHeader';
import CustomerMessages from './CustomerMessages';

interface ICustomerParams {
	customerId: string;
}

const CustomerPage: React.FC = () => {
	const { customerId } = useParams<ICustomerParams>();
	const loadCustomerQuery = useQuery(['customer', customerId], () => loadCustomer(customerId));

	const onRefetch = () => {
		loadCustomerQuery.refetch();
	};

	if (loadCustomerQuery.isLoading) {
		return <Loading />;
	}

	if (loadCustomerQuery.isError) {
		return <NetworkError handleRetry={onRefetch} />;
	}

	const customer = loadCustomerQuery.data!;

	return (
		<main className="c-main">
			<CContainer fluid>
				<CustomerPageHeader customer={customer} refetch={onRefetch} />
				<CustomerDetails customer={customer} />
				<CustomerMessages customerId={customerId} customer={customer} />
			</CContainer>
		</main>
	);
};

export default CustomerPage;
