import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import _t from 'counterpart';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import { NumberParam, useQueryParam } from 'use-query-params';
import { coalesce, formatDateTime, getFiltersCount } from '../helpers';
import PaginationTable, { ISorter } from '../components/PaginationTable';
import { IPayoutRequest } from '../types';
import { clearPayoutRequestFilters } from '../actions';
import { IPayoutRequestFilters } from '../reducers/filtersReducer';
import PayoutRequestsFilters from './PayoutRequestFilters';
import PayoutStatusBadge from './PayoutSatusBadge';
import { getPayoutRequests } from '../services/BackendService';
import { NetworkError } from '../components/NetworkError/NetworkError';

function payoutRequestFiltersToDTO(filters: IPayoutRequestFilters): {
	statuses: Array<string>;
} {
	return {
		statuses: filters.statuses.map((status) => status.value),
	};
}

const PayoutRequestsPage = () => {
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [orderDirection, setOrderDirection] = useQueryParam<'ASC' | 'DESC'>('orderBy');
	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;
	const payoutRequestFilters = useAppSelector((state) => state.filters.payoutRequestFilters, shallowEqual);
	const filtersDTO = payoutRequestFiltersToDTO(payoutRequestFilters);

	const dispatch = useAppDispatch();
	const history = useHistory();

	const clearFilters = () => {
		dispatch(clearPayoutRequestFilters());
	};

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const payoutRequestsQuery = useQuery(['payout-requests', offset, orderDirection, filtersDTO], () =>
		getPayoutRequests(limit, offset, orderDirection ?? 'DESC', filtersDTO.statuses)
	);
	const { isLoading, isError, data, refetch } = payoutRequestsQuery;

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onSorterChanged = ({ asc }: ISorter) => {
		const sortBy = asc ? 'ASC' : 'DESC';
		if (sortBy !== orderDirection) {
			setOrderDirection(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const fields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'requestedAt', label: _t('commission.requested-at') },
			{
				key: 'payoutWallet',
				label: _t('commission.payout-wallet'),
				sorter: false,
			},
			{ key: 'status', label: _t('global.status'), sorter: false },
			{ key: 'actionDate', label: _t('commission.action-date'), sorter: false },
			{
				key: 'rejectReason',
				label: _t('commission.reject-reason'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			payoutWallet: ({ payoutWalletId }: IPayoutRequest) => <td>{payoutWalletId}</td>,
			status: ({ approvedAt, rejectedAt }: IPayoutRequest) => (
				<td>
					<PayoutStatusBadge approvedAt={approvedAt} rejectedAt={rejectedAt} />
				</td>
			),
			actionDate: ({ approvedAt, rejectedAt }: IPayoutRequest) => (
				<td>{coalesce(approvedAt, rejectedAt) ? formatDateTime(coalesce(approvedAt, rejectedAt)) : '-'}</td>
			),
			requestedAt: ({ requestedAt }: IPayoutRequest) => <td>{formatDateTime(requestedAt)}</td>,
			rejectReason: ({ rejectReason }: IPayoutRequest) => (
				<td className="truncate">{rejectReason ? rejectReason : '-'}</td>
			),
		}),
		[]
	);

	const onRowClicked = (payoutRequest: IPayoutRequest) => {
		history.push(`/payout-requests/${payoutRequest.id}`);
	};

	if (isError) {
		return <NetworkError handleRetry={refetch} />;
	}

	const asc = orderDirection === 'ASC';
	const column = 'requestedAt';

	const filtersCount = getFiltersCount(payoutRequestFilters);

	return (
		<CContainer title={_t('commission.payout-requests')} fluid className="c-main main-holder">
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>
							<div>
								<div className="filters-header">
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={clearFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
									</div>
								</div>
								<PayoutRequestsFilters show={showFilters} onFilterChanged={onFilterChanged} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={fields}
								scopedSlots={scopedSlots}
								data={data?.payoutRequests || []}
								activePage={page || 1}
								pages={data?.count ? Math.ceil(data.count / Number(limit)) : 1}
								sorter={{ column, asc }}
								pagination
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								loading={isLoading || !data}
								clickableRows
								onRowClicked={onRowClicked}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</CContainer>
	);
};

export default PayoutRequestsPage;
