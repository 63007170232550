import React from 'react';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';
import { CBadge, CProgress, CProgressBar } from '@coreui/react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import {
  capitalize,
  formatCurrency,
  formatDateTime,
  getIconNameForCountry,
} from '../../helpers';
import { MessageType } from '../../types';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import UserStatusBadge from '../../components/UserStatusBadge';
import { useAppSelector } from '../../helpers/customHooks';

interface IProps {
  queryKey: string;
  fetch: (
    filter: string,
    limit: number,
    offset: number,
    orderBy: string,
    sources?: Array<string>
  ) => any;
}

const ReferralsTable = ({ queryKey, fetch }: IProps) => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [filter = '', setFilter] = useQueryParam('filter', StringParam);
  const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam(
    'orderBy',
    StringParam
  );

  const filters = useAppSelector((state) => state.filters.referralFilters);

  const sources = filters.sources
    .filter((option) => option.enabled)
    .map((option) => option.value);

  const limit = 10;
  const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

  const { data, isLoading } = useQuery(
    [queryKey, page, filter, orderBy, filters],
    () =>
      fetch(filter || '', limit, offset, orderBy || 'createdAt|DESC', sources)
  );

  const onPageChanged = (page: number) => {
    setPage(page, 'replaceIn');
  };

  const onSorterChanged = ({ column, asc }: ISorter) => {
    const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
    if (sortBy !== orderBy) {
      setOrderBy(sortBy, 'replaceIn');
      setPage(1, 'replaceIn');
    }
  };

  const searchTableData = (value: string) => {
    if (value !== filter) {
      setPage(1, 'replaceIn');
      setFilter(value, 'replaceIn');
    }
  };

  const [column, isAsc] = (orderBy || 'createdAt|DESC').split('|');
  const asc = isAsc === 'ASC';

  return (
    <PaginationTable
      scopedSlots={{
        level: (user: any) => (
          <td className="align-middle">
            <span>{user.level}</span>
          </td>
        ),
        name: (user: any) => (
          <td className="align-middle text-nowrap">
            {user.name && <Link to={`/referrals/${user.id}`}>{user.name}</Link>}
            {!user.name && user.directAffiliate && (
              <span
                className="font-italic text-muted"
                title={_t('referrals.personal-data-note')}
              >
                via &nbsp;
                <Link to={`/referrals/${user.directAffiliate.id}`}>
                  {user.directAffiliate.name}
                </Link>
              </span>
            )}
          </td>
        ),
        country: (user: any) => (
          <td className="align-middle">
            {getIconNameForCountry(user.country.toLowerCase()) ? (
              <CIcon
                name={getIconNameForCountry(user.country.toLowerCase())!}
                size="xl"
                title={user.countryName}
              />
            ) : (
              user.country
            )}
          </td>
        ),
        totalCoinsEarned: (user: any) => (
          <td className="align-middle">{user.totalCoinsEarned}</td>
        ),
        percent: (user: any) => (
          <td className="align-middle">
            <span>{Math.min(user.percent, 100).toFixed(0)} %</span>
            <CProgress>
              <CProgressBar
                value={Math.min(user.percent, 100)}
                max={100}
                precision={0}
              />
            </CProgress>
          </td>
        ),
        createdAt: (user: any) => (
          <td className="text-nowrap align-middle">
            {formatDateTime(user.createdAt)}
          </td>
        ),
        depositTotal: (user: any) => (
          <td className="align-middle">{formatCurrency(user.depositTotal)}</td>
        ),
        totalCommission: (user: any) => (
          <td className="align-middle">
            {formatCurrency(user.totalCommission)}
          </td>
        ),
        totalReferrals: (user: any) => (
          <td className="align-middle">{user.totalReferrals}</td>
        ),
        status: (user: any) => (
          <td className="align-middle">
            <UserStatusBadge status={user.status} />
            {user.availableContactMethods.includes(MessageType.Push) && (
              <CBadge className="ml-1" color="success">
                {_t('referrals.push')}
              </CBadge>
            )}
            {user.appInstalledAt && (
              <CBadge
                className="ml-1"
                color="success"
                title={user.appInstalledAt.toLocaleString()}
              >
                {_t('referrals.downloaded')}
              </CBadge>
            )}
          </td>
        ),
      }}
      tableFields={[
        { key: 'level', label: _t('referrals.level') },
        { key: 'name', label: _t('global.name') },
        { key: 'country', label: _t('referrals.country'), sorter: false },
        { key: 'totalCoinsEarned', label: _t('referrals.coins-earned') },
        { key: 'percent', label: _t('referrals.progress') },
        { key: 'createdAt', label: capitalize(_t('referrals.created')) },
        { key: 'depositTotal', label: _t('referrals.total-deposit') },
        { key: 'totalCommission', label: _t('referrals.total-commission') },
        { key: 'totalReferrals', label: _t('referrals.total-referrals') },
        {
          key: 'status',
          label: _t('referrals.status'),
          _style: { textTransform: 'capitalize' },
          sorter: false,
        },
      ]}
      data={data?.customers || []}
      loading={isLoading}
      pages={data?.pages || 0}
      activePage={page || 1}
      pagination
      sorter={{ column, asc }}
      onPageChanged={onPageChanged}
      onSearchValueChanged={searchTableData}
      onSorterChanged={onSorterChanged}
    />
  );
};

export default ReferralsTable;
