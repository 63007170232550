import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardTitle,
  CCardBody,
  CProgress,
  CProgressBar,
  CBadge,
} from '@coreui/react';
import moment from 'moment';
import Detail from '../../components/Detail';
import UserStatusBadge from '../../components/UserStatusBadge';
import {
  getIconNameForCountry,
  formatDateTime,
  formatCurrency,
} from '../../helpers';
import _t from 'counterpart';
import { ICustomer } from '../../types';
import { useAppSelector } from '../../helpers/customHooks';

interface IProps {
  customer: ICustomer;
}

const CustomerDetails = ({ customer }: IProps) => {
  const communicationLanguage = useAppSelector(
    (state) => state.user.communicationLanguage
  );

  return (
    <CRow>
      <CCol md={12}>
        <CCard>
          <CCardHeader>
            <div className="d-flex justify-content-between">
              <CCardTitle>{_t('global.details')}</CCardTitle>
              <UserStatusBadge status={customer.status} />
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md={6}>
                <dl>
                  <Detail title={_t('global.email')}>
                    <a href={`mailto:${customer.email}`}>{customer.email}</a>
                  </Detail>
                  <Detail title={_t('global.mobile-number')}>
                    {customer.mobilenumber}
                  </Detail>
                  <Detail title={_t('global.country')}>
                    {customer.country && (
                      <>
                        {getIconNameForCountry(
                          customer.country.toLowerCase()
                        ) && (
                          <>
                            <CIcon
                              name={
                                getIconNameForCountry(
                                  customer.country.toLowerCase()
                                )!
                              }
                              size="xl"
                              title={customer.country}
                            />
                            &nbsp;
                          </>
                        )}
                        {customer.countryName}
                      </>
                    )}
                  </Detail>
                  <Detail title={_t('global.progress')}>
                    <CProgress>
                      <CProgressBar
                        value={Math.min(customer.percent || 0, 100)}
                        max={100}
                        showPercentage
                        showValue
                        precision={0}
                      />
                    </CProgress>
                  </Detail>
                  <Detail title={_t('customer.source')}>
                    {customer.source ? customer.source : '-'}
                  </Detail>
                </dl>
              </CCol>
              <CCol md={6}>
                <dl>
                  <Detail title={_t('customer.app-installed')}>
                    {customer.appInstalledAt ? (
                      formatDateTime(customer.appInstalledAt)
                    ) : (
                      <CBadge color="danger">
                        {_t('customer.not-yet-installed')}
                      </CBadge>
                    )}
                  </Detail>

                  <Detail title={_t('customer.last-login')}>
                    {customer.lastLoginDate ? (
                      <span title={formatDateTime(customer.lastLoginDate)}>
                        {moment(customer.lastLoginDate)
                          .locale(communicationLanguage)
                          .fromNow()}
                      </span>
                    ) : (
                      <CBadge color="danger">
                        {_t('customer.not-logged-in-yet')}
                      </CBadge>
                    )}
                  </Detail>

                  <Detail title={_t('customer.total-logins')}>
                    {customer.totalLogins
                      ? customer.totalLogins.toLocaleString()
                      : 0}
                  </Detail>

                  <Detail title={_t('customer.deposit-total')}>
                    {customer.depositTotal !== null
                      ? formatCurrency(customer.depositTotal)
                      : _t('global.please-wait')}
                  </Detail>

                  <Detail title={_t('customer.total-coins-earned')}>
                    {customer.totalCoinsEarned}
                  </Detail>

                  <Detail title={_t('customer.first-time-deposit')}>
                    {customer.hasFTD ? (
                      <CBadge color="success">
                        {_t('customer.has-first-time-deposit')}
                      </CBadge>
                    ) : (
                      <CBadge color="danger">
                        {_t('customer.no-first-time-deposit')}
                      </CBadge>
                    )}
                  </Detail>
                </dl>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default CustomerDetails;
