import React, { useState } from 'react';
import {
	CButton,
	CButtonToolbar,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CContainer,
	CForm,
	CFormGroup,
	CFormText,
	CInput,
	CLabel,
	CRow,
	CSelect,
} from '@coreui/react';
import _t from 'counterpart';
import { MessageType } from '../types';
import MessageBox from '../components/MessageBox';
import { extractErrorMessage, formatDateTime, truncate } from '../helpers';
import { Callout } from '../components/Callout';
import MessageTypeBadge from '../components/MessageTypeBadge';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { loadGroupMessages, loadTargetGroups, sendGroupMessage } from '../services/BackendService';
import Loading from '../components/Loading';
import { NetworkError } from '../components/NetworkError/NetworkError';
import { CONTACT_METHODS } from '../config';
import { MainTitle } from '../components/MainTitle/MainTitle';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../actions';
import { NumberParam, useQueryParam } from 'use-query-params';
import PaginationTable from '../components/PaginationTable';

interface SendGroupMessageParams {
	type: MessageType;
	targetGroupId: string;
	message: string;
	subject: string;
}

const MessagesPage = () => {
	const [messageText, setMessageText] = useState<string>('');
	const [targetGroupId, setTargetGroupId] = useState<string | null>(null);
	const [messageType, setMessageType] = useState<MessageType | null>(null);
	const [messageSubject, setMessageSubject] = useState<string>('');
	const [page, setPage] = useQueryParam('page', NumberParam);

	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const messagesQuery = useQuery(['group-messages', page], () => loadGroupMessages(limit, offset));
	const targetGroupsQuery = useQuery('target-groups', loadTargetGroups);

	const pages = Math.round((messagesQuery.data?.count || 0) / limit);

	const sendGroupMessageMutation = useMutation(
		(params: SendGroupMessageParams) =>
			sendGroupMessage(params.type, params.targetGroupId, params.message, params.subject),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t('messages.message-sent-successfully')));
				queryClient.invalidateQueries('group-messages');
			},
			onError: (e) => {
				dispatch(showErrorToast(extractErrorMessage(e)));
			},
		}
	);

	const handleRefetchAll = () => {
		messagesQuery.refetch();
		targetGroupsQuery.refetch();
	};

	const sendMessage = () => {
		sendGroupMessageMutation.mutate({
			type: messageType!,
			targetGroupId: targetGroupId!,
			message: messageText,
			subject: messageSubject,
		});
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
		queryClient.invalidateQueries(['group-messages']);
	};

	const updateMessageSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMessageSubject(e.target.value);
	};

	if (messagesQuery.isLoading || targetGroupsQuery.isLoading) {
		return <Loading />;
	}

	if (messagesQuery.isError || targetGroupsQuery.isError) {
		return <NetworkError handleRetry={handleRefetchAll} />;
	}

	return (
		<CContainer fluid className="c-main main-holder">
			<CRow>
				<CCol>
					<MainTitle>{_t('sidebar.messages')}</MainTitle>
					<Callout color="primary" label={_t('messages.title')} value={_t('messages.description')} />
				</CCol>
			</CRow>
			<CRow>
				<CCol sm="12" md="8">
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('messages.last-messages')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								scopedSlots={{
									date: (message: any) => <td className="align-middle">{formatDateTime(message.createdAt)}</td>,
									type: () => (
										<td className="align-middle">
											<MessageTypeBadge type={MessageType.Message} />
										</td>
									),
									recipients: (message: any) => <td className="align-middle">{message.noRecipients}</td>,
									subject: (message: any) => <td className="align-middle">{message.subject}</td>,
									text: (message: any) => <td className="align-middle">{truncate(message.text, 40)}</td>,
								}}
								tableFields={[
									{ key: 'date', label: _t('global.date') },
									{ key: 'type', label: _t('global.type') },
									{ key: 'recipients', label: _t('global.recipients') },
									{ key: 'subject', label: _t('messages.subject'), sorter: false },
									{ key: 'text', label: _t('global.text'), sorter: false },
								]}
								data={messagesQuery.data?.messages || []}
								activePage={page || 1}
								pages={pages || 0}
								pagination
								onPageChanged={onPageChanged}
								loading={false}
							/>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol sm="12" md="4">
					<CCard className="card--form">
						<CCardHeader>
							<CCardTitle>{_t('action.send-new-message')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CForm action="" method="post">
								<CFormGroup>
									<CLabel htmlFor="nf-type">{_t('messages.contact-method')}</CLabel>
									<CSelect id="nf-type" name="nf-type" onChange={(event: any) => setMessageType(event.target.value)}>
										<option value="">{_t('action.please-select')}</option>
										{CONTACT_METHODS.map((t) => (
											<option value={t.id} key={`type-${t.id}`}>
												{_t(`messages.${t.id}`)}
											</option>
										))}
									</CSelect>
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="nf-target-group">{_t('messages.target-group')}</CLabel>
									<CSelect
										id="nf-target-group"
										name="nf-target-group"
										onChange={(event: any) => setTargetGroupId(event.target.value)}
									>
										<option value="">{_t('action.please-select')}</option>
										{targetGroupsQuery.data!.map((t) => (
											<option value={t.id} key={`target-group-${t.id}`}>
												{_t(`target-groups.${t.id.toLowerCase()}`)}
											</option>
										))}
									</CSelect>
									<CFormText className="help-block">
										{targetGroupId && messageType
											? `${_t('messages.message-delivered')} ~${
													targetGroupsQuery.data!.find((t) => t.id === targetGroupId)!.noRecipients[messageType] || 0
											  } ${_t('global.people')}`
											: _t('messages.select-group')}
									</CFormText>
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t('messages.subject')}</CLabel>
									<CInput type="text" value={messageSubject} onChange={updateMessageSubject} />
								</CFormGroup>
								<MessageBox
									error={sendGroupMessageMutation.error}
									onChange={(text: string) => {
										setMessageText(text);
									}}
								/>

								<CButtonToolbar justify="start">
									<CButton
										color="primary"
										onClick={sendMessage}
										disabled={!(targetGroupId && messageType && messageText.length > 0)}
										className="btn--box"
									>
										{_t('action.send-message')}
									</CButton>
								</CButtonToolbar>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</CContainer>
	);
};

export default MessagesPage;
