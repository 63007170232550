import React from 'react';
import styles from './FullscreenSpinner.module.scss';
import { CSpinner } from "@coreui/react";

const FullscreenSpinner = () => {
  return (
    <div className={styles.container}>
      <CSpinner />
    </div>
  )
}

export default FullscreenSpinner;
