import { CFormGroup, CLabel, CTextarea, CFormText } from '@coreui/react';
import React, { useState } from 'react';
import _t from 'counterpart';
import { extractErrorMessage, extractValidationErrorMessage } from '../helpers';

const MAX_CHARS = 160;

interface Props {
  onChange: (text: string) => void;
  error: any;
}

const MessageBox: React.FC<Props> = ({ onChange, error }) => {
  const validationErrors = extractValidationErrorMessage(error);

  const onChangeInt = (event: any) => {
    const { value } = event.target;
    onChange(value);
  };

  const findError = (paramName: string) => {
    if (validationErrors) {
      const inputError = validationErrors.find((e: any) => e.param === paramName);
      if (inputError) {
        return inputError.msg;
      }
    }
    return null;
  };

  return (
    <CFormGroup>
      <CLabel htmlFor="nf-message">{_t('global.message')}</CLabel>
      <CTextarea
        id="nf-message"
        name="nf-password"
        placeholder={_t('messages.messagebox-placeholder')}
        onChange={onChangeInt}
      />
      {findError('text') && <CLabel className="text-danger">{findError('text')}</CLabel>}
      {error && !validationErrors && <CLabel className="text-danger">{extractErrorMessage(error)}</CLabel>}
    </CFormGroup>
  );
};

export default MessageBox;
