import React, { useCallback } from 'react';
import _t from 'counterpart';
import { CButton, CCol, CForm, CFormGroup, CRow } from '@coreui/react';
import CustomSelect, { ISelectOption } from '../components/CustomSelect';
import { shallowEqual } from 'react-redux';
import { setCommissionFilters } from '../actions';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';

interface IProps {
	show: boolean;
	onFilterChanged: () => void;
}

const CommissionsFilters = ({ show }: IProps) => {
	const dispatch = useAppDispatch();
	const activeFilters = useAppSelector((state) => state.filters.commissionFilters, shallowEqual);
	const setFilters = useCallback(
		(filters: Partial<{ statuses: Array<ISelectOption> }>) => {
			dispatch(setCommissionFilters(filters));
		},
		[dispatch]
	);

	const statuses = [
		{ value: 'PAIDOUT', label: _t('commission.paid-out') },
		{ value: 'PENDING', label: _t('commission.pending') },
	];

	const resetStatus = () => {
		setFilters({
			statuses: [],
		});
	};

	const handleStatusChanged = (statuses: any) => {
		setFilters({
			statuses: statuses,
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('global.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetStatus}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.statuses ?? null}
							options={statuses}
							onChange={handleStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default CommissionsFilters;
