import { AnyAction } from 'redux';
import {
	CLEAR_COMMISSION_FILTERS,
	CLEAR_REFERRAL_FILTERS,
	SET_COMMISSION_FILTERS,
	SET_REFERRAL_FILTERS,
	CLEAR_PAYOUT_REQUEST_FILTERS,
	SET_PAYOUT_REQUEST_FILTERS,
} from '../actions';
import { ISelectOption } from '../components/CustomSelect';
import { ISelectOption as IToggleSelectOption } from '../components/SelectMultipleFilter';

export interface ICommissionFilters {
	statuses: Array<ISelectOption>;
}

export interface IReferralFilters {
	sources: Array<IToggleSelectOption>;
}

export interface IPayoutRequestFilters {
	statuses: Array<ISelectOption>;
}

export interface IFiltersState {
	commissionFilters: ICommissionFilters;
	referralFilters: IReferralFilters;
	payoutRequestFilters: IPayoutRequestFilters;
}

const initialState = {
	commissionFilters: {
		statuses: [],
	},
	payoutRequestFilters: {
		statuses: [],
	},
	referralFilters: {
		sources: [],
	},
};

function filtersReducer(state: IFiltersState | undefined, action: AnyAction): IFiltersState {
	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
		case SET_COMMISSION_FILTERS:
			return {
				...state,
				commissionFilters: {
					...action.payload.filters,
				},
			};
		case CLEAR_COMMISSION_FILTERS:
			return {
				...state,
				commissionFilters: {
					...initialState.commissionFilters,
				},
			};
		case SET_REFERRAL_FILTERS:
			return {
				...state,
				referralFilters: {
					...action.payload.filters,
				},
			};
		case CLEAR_REFERRAL_FILTERS:
			return {
				...state,
				referralFilters: {
					...initialState.referralFilters,
					sources: state.referralFilters.sources.map((option) => {
						return {
							...option,
							enabled: false,
						};
					}),
				},
			};
		case SET_PAYOUT_REQUEST_FILTERS:
			return {
				...state,
				payoutRequestFilters: {
					...action.payload.filters,
				},
			};
		case CLEAR_PAYOUT_REQUEST_FILTERS:
			return {
				...state,
				payoutRequestFilters: {
					...initialState.payoutRequestFilters,
				},
			};
		default:
			return state;
	}
}

export default filtersReducer;
