import { CForm, CRow, CCol, CFormGroup, CButton } from '@coreui/react';
import React, { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import _t from 'counterpart';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import { IPayoutRequestFilters } from '../reducers/filtersReducer';
import { setPayoutRequestFilters } from '../actions';
import CustomSelect from '../components/CustomSelect';

interface IProps {
	show: boolean;
	onFilterChanged: () => void;
}

const PayoutRequestsFilters = ({ show }: IProps) => {
	const dispatch = useAppDispatch();

	const activeFilters = useAppSelector((state: any) => state.filters.payoutRequestFilters, shallowEqual);
	const setFilters = useCallback(
		(filters: Partial<IPayoutRequestFilters>) => {
			dispatch(setPayoutRequestFilters(filters));
		},
		[dispatch]
	);

	const statuses = [
		{ value: 'APPROVED', label: _t('commission.approved').toLocaleUpperCase() },
		{ value: 'REJECTED', label: _t('commission.rejected').toLocaleUpperCase() },
		{ value: 'PENDING', label: _t('commission.pending') },
	];

	const resetStatus = () => {
		setFilters({
			statuses: [],
		});
	};

	const handleStatusChanged = (statuses: any) => {
		setFilters({
			statuses: statuses,
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('global.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetStatus}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.statuses ?? null}
							options={statuses}
							onChange={handleStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default PayoutRequestsFilters;
