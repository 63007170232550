import { AnyAction } from 'redux';
import { SET_SIDEBAR_SHOWN, SET_LANGUAGE, SHOW_GLOBAL_LOADER, HIDE_GLOBAL_LOADER } from "../actions";

export interface CoreState {
  sidebarShown: boolean | '' | 'responsive';
  language: string;
  globalLoading: boolean;
}

const initialState: CoreState = {
  sidebarShown: 'responsive',
  language: 'en',
  globalLoading: false,
};

function coreReducer(
  state: CoreState | undefined,
  action: AnyAction
): CoreState {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case SET_SIDEBAR_SHOWN:
      return {
        ...state,
        sidebarShown: action.payload.shown,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case SHOW_GLOBAL_LOADER:
      return {
        ...state,
        globalLoading: true,
      };
    case HIDE_GLOBAL_LOADER:
      return {
        ...state,
        globalLoading: false,
      };
    default:
      return state;
  }
}

export default coreReducer;
