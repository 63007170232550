import React, { useState } from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CFormGroup,
  CLabel,
  CInput,
  CModalFooter,
  CButton,
} from '@coreui/react';
import { useMutation } from 'react-query';
import _t from 'counterpart';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { updateCustomer } from '../../services/BackendService';
import { useAppDispatch } from '../../helpers/customHooks';
import { showErrorToast, showSuccessToast } from '../../actions';
import { extractErrorMessage } from '../../helpers';

interface IProps {
  show: boolean;
  onHide: (refetch?: boolean) => void;
  customerId: string;
}

interface IUpdateParams {
  id: string;
  source: string;
}

const EditSourceModal = ({ show, onHide, customerId }: IProps) => {
  const [source, setSource] = useState<string>('');

  const dispatch = useAppDispatch();

  const editSourceMutation = useMutation(
    ({ id, source }: IUpdateParams) => updateCustomer(id, { source }),
    {
      onSuccess: () => {
        dispatch(showSuccessToast(_t('customer.source-updated-successfully')));
        onHide(true);
      },
      onError: (e) => {
        dispatch(showErrorToast(extractErrorMessage(e)));
      },
    }
  );

  const hideSourceModal = () => {
    onHide();
  };

  const updateSource = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setSource(target.value);
  };

  const invokeMutation = () => {
    editSourceMutation.mutate({ id: customerId, source });
  };

  return (
    <CModal show={show} onClose={hideSourceModal}>
      <CModalHeader>
        <CModalTitle>{_t('customer.edit-customer-source')}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormGroup>
          <CLabel>{_t('customer.source')}</CLabel>
          <CInput type="text" value={source} onChange={updateSource} />
        </CFormGroup>
      </CModalBody>
      <CModalFooter>
        <ButtonWithLoader
          isLoading={editSourceMutation.isLoading}
          buttonColor="primary"
          type="submit"
          spinnerColor="secondary"
          title={_t('global.update')}
          className="mr-2"
          onClick={invokeMutation}
        />
        <CButton color="light" variant="outline" onClick={hideSourceModal}>
          {_t('global.cancel')}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default React.memo(EditSourceModal);
