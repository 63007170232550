import React, { useEffect, useMemo, useState } from 'react';
import {
	CButton,
	CButtonToolbar,
	CCallout,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CContainer,
	CLabel,
	CLink,
	CRow,
} from '@coreui/react';
import _t from 'counterpart';
import { getCommissionLedgerRecords, getPayoutRequests, hasPendingPayoutRequest } from '../services/BackendService';
import PaginationTable from '../components/PaginationTable';
import { useQuery, useQueryClient } from 'react-query';
import { NumberParam, useQueryParam } from 'use-query-params';
import { IAffiliateCommissionLedger } from '../types';
import { coalesce, formatCurrency, formatDateTime, getFiltersCount } from '../helpers';
import WalletPickerModal from './RequestTransferModal';
import { MainTitle } from '../components/MainTitle/MainTitle';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import CommissionsFilters from './CommissionsFilters';
import { shallowEqual } from 'react-redux';
import { commissionFiltersToDTO } from '../helpers/commissionFiltersToDTO';
import { clearCommissionFilters } from '../actions';
import { useHistory } from 'react-router';
import Error from '../components/Error';
import CIcon from '@coreui/icons-react';

const CommissionsPage = () => {
	const limit = 10;
	const [page, setPage] = useQueryParam('page', NumberParam);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [orderDirection, setOrderDirection] = useQueryParam<'ASC' | 'DESC'>('orderBy');
	const [showWalletModal, setShowWalletModal] = useState<boolean>(false);
	const [payoutRequestButtonDisabled, setPayoutRequestButtonDisabled] = useState<boolean>(false);

	const dispatch = useAppDispatch();
	const history = useHistory();
	const queryClient = useQueryClient();

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const commissionFilters = useAppSelector((state) => state.filters.commissionFilters, shallowEqual);
	const filtersDTO = commissionFiltersToDTO(commissionFilters);

	const payoutRequestsQuery = useQuery(['has-pending-payout-request'], () => hasPendingPayoutRequest());

	const { data, isError } = payoutRequestsQuery;

	const onErrorRetry = () => {
		queryClient.invalidateQueries('has-pending-payout-request');
	};

	useEffect(() => {
		if (data) {
			setPayoutRequestButtonDisabled(true);
		}
	});

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const tableFields = useMemo(
		() => [
			{ key: 'id', label: _t('commission.id'), sorter: false },
			{ key: 'referral', label: _t('commission.referral'), sorter: false },
			{
				key: 'commissionType',
				label: _t('commission.commission-type'),
				sorter: false,
			},
			{ key: 'actionType', label: _t('commission.action-type'), sorter: false },
			{
				key: 'actionDetails',
				label: _t('commission.action-details'),
				sorter: false,
			},
			{
				key: 'commissionAmount',
				label: _t('commission.amount'),
				sorter: false,
			},
			{ key: 'createdAt', label: _t('commission.date'), sorter: false },
			{ key: 'payoutAt', label: _t('commission.payout'), sorter: false },
		],
		[]
	);
	const scopedSlots = useMemo(
		() => ({
			referral: ({ referral }: IAffiliateCommissionLedger) => (
				<td>
					<CLink href={`/referrals/${referral.id}`}>{referral.name}</CLink>
				</td>
			),
			createdAt: ({ createdAt }: IAffiliateCommissionLedger) => (
				<td className="text-nowrap align-middle">{formatDateTime(createdAt)}</td>
			),
			commissionAmount: ({ commissionAmount, correctedCommissionAmount }: IAffiliateCommissionLedger) => (
				<td>{formatCurrency(coalesce(correctedCommissionAmount, commissionAmount))}</td>
			),
			payoutAt: ({ payoutAt }: IAffiliateCommissionLedger) => (
				<td className="text-nowrap align-middle">{payoutAt ? formatDateTime(payoutAt) : '-'}</td>
			),
		}),

		[]
	);

	const getCommissionLedgerRecordsQuery = useQuery(
		['ledgers', limit, offset, commissionFilters],
		() => getCommissionLedgerRecords(limit, offset, orderDirection ?? 'DESC', filtersDTO.statuses),
		{}
	);

	const clearFilters = () => {
		dispatch(clearCommissionFilters());
	};

	if (orderDirection === undefined) {
		setOrderDirection('DESC');
	}

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const commissionLedgerRecordsCount = getCommissionLedgerRecordsQuery.data?.count;

	const onPayoutRequestButtonClicked = () => {
		setShowWalletModal(true);
	};

	const onViewPayoutRequestsClicked = () => {
		history.push(`/payout-requests`);
	};

	const onPayoutRequestButtonClosed = () => {
		setShowWalletModal(false);
		queryClient.invalidateQueries('has-pending-payout-request');
	};

	if (getCommissionLedgerRecordsQuery.isError) {
		return <Error onRetry={getCommissionLedgerRecordsQuery.refetch} />;
	}

	const pagesCount = commissionLedgerRecordsCount ? Math.ceil(commissionLedgerRecordsCount / Number(limit)) : 1;
	const filtersCount = getFiltersCount(commissionFilters);
	return (
		<CContainer fluid className="c-main main-holder">
			<CRow>
				<CCol>
					<MainTitle>{_t('sidebar.commissions')}</MainTitle>
					<CCallout color="primary" className="bg-white">
						<small className="text-value-lg">{_t('payments.title')}</small>
						<div>
							{
								<>
									{_t('payments.earning-description')}
									<br />
									{_t('payments.transfer-description')}
									<hr />
								</>
							}
						</div>
						<div className="d-flex align-items-center w-100">
							<h5>
								<span>
									{_t('commission.payout-amount')}:&nbsp;
									<b>{formatCurrency(getCommissionLedgerRecordsQuery.data?.totalPayoutAmount ?? 0)}</b>
								</span>
							</h5>
							<div className="ml-auto justify-content-end d-inline-flex">
								<CButtonToolbar>
									<CButton color="primary" className="mr-3" onClick={onViewPayoutRequestsClicked}>
										{_t('commission.view-payout-requests')}
									</CButton>
								</CButtonToolbar>
								<CButtonToolbar>
									<CButton
										color="primary"
										disabled={payoutRequestButtonDisabled}
										onClick={onPayoutRequestButtonClicked}
									>
										{_t('commission.request-payout')}
									</CButton>
								</CButtonToolbar>
							</div>
						</div>
						{payoutRequestButtonDisabled && (
							<CLabel className="text-danger float-right">
								<CIcon name="cil-warning" size="xl" className="mb-2" /> {_t('commission.payout-request-warning')}
							</CLabel>
						)}
					</CCallout>
				</CCol>
			</CRow>

			<CCard>
				<CCardHeader className="pb-0">
					<CCol className="d-flex align-items-center">
						<CCardTitle className="flex-grow-1">{_t('commission.commission-ledger-records')}</CCardTitle>
					</CCol>
					<br />
					<div>
						<div className="filters-header">
							<div className="filters-header-inline w-100">
								<div className="filters-header-buttons float-left">
									<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
										<div className="d-flex justify-content-center align-items-center">
											<span>{_t('global.filters')}</span>
											{filtersCount > 0 && <div className="filters-header-buttons-active-inner">{filtersCount}</div>}
											<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
										</div>
									</CButton>
									<CButton onClick={clearFilters} className="filters-header-buttons-reset">
										{_t('action.reset')}
									</CButton>
								</div>
							</div>
						</div>
						<CommissionsFilters show={showFilters} onFilterChanged={onFilterChanged} />
					</div>
				</CCardHeader>
				<CCardBody>
					<PaginationTable
						onPageChanged={onPageChanged}
						tableFields={tableFields}
						scopedSlots={scopedSlots}
						pagination
						pages={pagesCount}
						data={getCommissionLedgerRecordsQuery.data?.commissions || []}
						loading={getCommissionLedgerRecordsQuery.isLoading}
						activePage={page || 1}
					/>
				</CCardBody>
			</CCard>
			<WalletPickerModal
				show={showWalletModal}
				onClose={onPayoutRequestButtonClosed}
				totalAmount={getCommissionLedgerRecordsQuery.data?.totalPayoutAmount ?? 0}
			/>
		</CContainer>
	);
};

export default CommissionsPage;
